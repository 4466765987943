<template>
  <div>
    <v-alert 
      outlined
      :value="alert"
      type="error"
      transition="scale-transition">
      {{$t('errors.errors')}}
    </v-alert>
    <v-text-field
      :label="$t('fields.name')"
      ref="email"
      flat
      outlined
      solo
      class="rounded-lg"
      v-model="signup.name"
      type="text"
      :rules="[ 
        v => !!v || 'El campo es obligatorio',
        v => ((v || '').match(/\b(\w+)\b/g) || []).length > 1 || 'Debe contener nombre y apellido'
      ]">
    </v-text-field>

    <v-text-field
      :label="$t('fields.email')"
      ref="email"
      solo
      flat
      outlined
      class="rounded-lg"
      v-model="signup.email"
      type="text"
      :rules="[ v => !!v || 'El campo es obligatorio']">
    </v-text-field>

    <v-text-field
      id="password"
      :label="$t('fields.password')"
      ref="password"
      solo
      flat
      outlined
      class="rounded-lg"
      v-model="signup.password"
      :type="show_password ? 'text' : 'password'"
      :append-icon="show_password ? 'mdi-eye' : 'mdi-eye-off'"
      @click:append="show_password = !show_password"
      :rules="[ v => !!v || 'El campo es obligatorio']">
    </v-text-field>
  </div>
</template>

<script>
export default {
  name: 'FirstStep',
  data: () => ({
    loading: false,
    alert: false,
    errorMessages: "",
    show_password: false,
    signup: {
      email: '',
      password: '',
    }
  }),

  methods:{
    validate(){
      return true
    }
  },
}
</script>
